//React
import React, { Component } from "react";
import { DataAccessService } from 'Core/api';
// Polaris
import {
  Badge,
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  LineChart,
  Link,
  Pagination,
  SegmentedControl,
  SpaceBetween,
  Spinner,
  Table,
  Tabs,
} from '@amzn/awsui-components-react';

import { personalRisk, riskColumnDefinitions, totalRisks } from 'Helper/riskMetrics';
import riskModal from '../helpers/riskDetailsModal';
import { formatRiskGraphData } from 'Helper/formatRiskGraphData';
import getMetricsForUser from '../helpers/getMetricsForUser';
import getDelegationsForUser from '../helpers/getDelegationsForUser';
import installationStatus from '../helpers/installationStatus';
import blockedCommitModal from '../helpers/blockedCommitModal';
import { organizeBlockedCommits, organizeMetrics, organizeUserIssueList } from 'Helper/organizeData';
import { negativeIconBuilder, positiveIconBuilder } from 'Helper/iconBuilders'
import { User } from "Core/user";

import { LocalStorage } from "Core/storage";

// Styles
import { LeaveIcon } from "../helpers/iconBuilders";
import ServiceAppLayout from "../components/ServiceAppLayout";
import { FlashbarPublisher } from "../components/FlashbarPublisher";

const personalColumnDefinitions = [
  {
    id: "metric",
    header: "Metric",
    cell: item => item.metric || "0"
  },
  {
    id: "oneDay",
    header: "One day",
    cell: item => item.oneDay || "0"
  },
  {
    id: "sevenDays",
    header: "Seven days",
    cell: item => item.sevenDays || "0"
  },
  {
    id: "thirtyDays",
    header: "Thirty days",
    cell: item => item.thirtyDays || "0"
  }
]

const ValueWithLabel = ({ label, children }) => (
  <SpaceBetween direction="vertical" size="s">
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </SpaceBetween>
)

// Media

//Components
class MetricsHomePage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      flashBarError: false,
      flashBarSuccess: false,
      header: "",
      userContent: "",
    }
    this.headerUpdater = this.headerUpdater.bind(this)
  }

  headerUpdater(header, data) {
    this.setState({
      header: header,
      userContent: <Table
        header={<Header>Aggregrated Data for {header}'s Direct Reports</Header>}
        columnDefinitions={personalColumnDefinitions}
        items={data}
      />
    })
  }

  render() {
    return (
      <ServiceAppLayout
        className="awsui-util-no-gutters"
        page="MetricsHomePage"
        content={<div style={{ minHeight: '100vh' }}><MetricsContent {...this.props} headerUpdater={this.headerUpdater} /></div>}
        toolsHide={true} />
    );
  }
}

// The content in the main content area of the App layout
class MetricsContent extends Component {
  constructor(props) {
    super(props)
    this.client = new DataAccessService();
    const user = (new User()).userId;
    this.state = {
      loading: true,
      dataLoad: false,
      delagateLoad: false,
      data: [],
      selectedItems: [],
      personalItems: this.props.personalItems,
      reportItems: [],
      user,
      isUserOnLeave: false,
      reportsOnLeaveCount: 0,
      selectedUser: user,
      selectedUserNicename: user,
      reportOrganizedData: {},
      chain: [{ text: user, href: '#' }],
      delegations: [],
      modalVisible: false,
      modalObject: null,
      userIssueList: [],
      currentPageIndex: 1,
      riskPageIndex: 1,
      userIssueListTableLen: 10,
      riskData: { riskList: [], risksAvailable: false },
      visibleRiskGraphData: [],
      riskGraphData: [],
      riskGraphType: "personal",
      riskCounts: {},
    };

    this.setModalVisibility = this.setModalVisibility.bind(this);
    this.setModalToRiskData = this.setModalToRiskData.bind(this);
    this.setRiskPageIndex = this.setRiskPageIndex.bind(this);
  }

  getBreadcrumbChainList(user, selectedUser, chain) {
    if (selectedUser === "ROOT") {
      return [{ text: user, href: '#' }, { text: selectedUser, href: '#' }];
    }

    const delegationsInChain = chain.filter(chainUser => this.state.delegations.includes(chainUser));
    if (user === selectedUser) {
      return [{ text: user, href: '#' }];
    } else if ((chain && chain.includes(user))) {
      const trimmedChainList = chain.slice(chain.indexOf(user));
      trimmedChainList.push(selectedUser);
      return trimmedChainList.map((username) => { return { text: username, href: '#' } })
    } else if (delegationsInChain.length > 0) {
      const trimmedChainList = [user, ...chain.slice(chain.indexOf(delegationsInChain[0]))];
      trimmedChainList.push(selectedUser);
      return trimmedChainList.map((username) => { return { text: username, href: '#' } })
    }
    return [{ text: user, href: '#' }, { text: selectedUser, href: '#' }];
  }

  async componentDidMount() {
    FlashbarPublisher.pushItem({
      type: "warning",
      dismissible: false,
      content:
        "Amazon Highly Confidential. This page contains sensitive information."
    });
    this.setState({
      loading: true,
    });

    this.loadData(this.state.user);
    this.loadDelegations(this.state.user);
  }

  async runSearch(e) {
    e.preventDefault()
    this.setState({ selectedUser: this.state.searchValue });
    this.setState({ loading: true });
    await this.loadData(this.state.searchValue);
    this.setState({ loading: false });
  }

  async loadData(user) {
    let result;
    this.setState({ dataLoad: true });
    this.setState({ personalInstallStatus: (<Spinner size="large" />) });
    this.setState({ visibleRiskGraphData: [] });
    this.setState({ riskGraphData: [] });
    try {
      result = await getMetricsForUser(this.client, {}, user)
    } finally {
      if (result && result.public) {
        const privateData = result.private;
        const publicData = result.public;
        const aggregatedData = result.aggregated;
        const privateArray = organizeMetrics(privateData, publicData);
        const [blockedCommits, numBlockedCommits] = organizeBlockedCommits(publicData);
        const chain = result.chain ? result.chain : [];
        const userIssueList = organizeUserIssueList(aggregatedData);
        const riskList = personalRisk(result.risks, user);
        const riskCounts = result.activeRiskCount
        const userOnLeave = result.on_leave

        const reportData = result.reports;
        let reportsOnLeave = 0;
        let reportArray = [];
        let reportOrganizedData = {};
        let totalReport = {
          user: "Total",
          installations: 0,
          bypassAttempts: 0,
          missingInstallations: 0,
          reports: 0,
          reportInstallations: 0,
          reportBypassAttempts: 0,
          reportMissingInstallations: 0,
          reportsOnLeave: 0,
          isUserOnLeave: false,
          reportPersonalNumBlockedCommits: 0,
          reportPersonalBlockedCommits: 0,
          reportNumBlockedCommits: 0,
          reportBlockedCommits: 0,
          reportsOnboarded: 0,
          username: "total"
        }
        if (reportData) {
          for (let report of reportData) {
            const [reportPersonalBlockedCommits, reportPersonalNumBlockedCommits] = organizeBlockedCommits(report.public)
            const [reportBlockedCommits, reportNumBlockedCommits] = organizeBlockedCommits(report.aggregated.public)
            reportOrganizedData[report.username] = organizeMetrics(report.aggregated.private, report.aggregated.public)
            reportArray.push(
              {
                user: report.nicename,
                onboarded: report.onboarded,
                installations: report.public.thirty["installs"],
                bypassAttempts: report.public.thirty["bypasses"],
                missingInstallations: report.public.thirty["missing_installs"],
                reports: report.aggregated.count,
                reportsOnboarded: report.aggregated.onboarded_count,
                reportInstallations: report.aggregated.public.thirty["installs"],
                reportBypassAttempts: report.aggregated.public.thirty["bypasses"],
                reportMissingInstallations: report.aggregated.public.thirty["missing_installs"],
                reportsOnLeave: report.aggregated.on_leave_count,
                reportPersonalNumBlockedCommits: reportPersonalNumBlockedCommits,
                reportPersonalBlockedCommits: reportPersonalBlockedCommits,
                reportNumBlockedCommits: reportNumBlockedCommits,
                reportBlockedCommits: reportBlockedCommits,
                username: report.username,
                isUserOnLeave: report.on_leave,
              }
            )
            if (report.on_leave) reportsOnLeave++
          }

          const [totalReportBlockedCommits, totalReportNumBlockedCommits] = organizeBlockedCommits(aggregatedData.public)
          totalReport.reports = aggregatedData.count
          totalReport.reportsOnLeave = aggregatedData.on_leave_count
          totalReport.reportsOnboarded = aggregatedData.onboarded_count
          totalReport.reportInstallations = aggregatedData.public.thirty["installs"]
          totalReport.reportBypassAttempts = aggregatedData.public.thirty["bypasses"]
          totalReport.reportMissingInstallations = aggregatedData.public.thirty["missing_installs"]
          totalReport.reportNumBlockedCommits = totalReportNumBlockedCommits
          totalReport.reportBlockedCommits = totalReportBlockedCommits
        }
        const riskGraphType = reportArray.length > 0 ? "overall" : "personal"
        const riskGraphData = formatRiskGraphData(riskCounts)
        const selectedGraphData = riskGraphType === "overall" ? riskGraphData.overallData : riskGraphData.personalData

        this.setState({ personalItems: privateArray });
        this.setState({ personalNumCommitItems: numBlockedCommits });
        this.setState({ personalCommitItems: blockedCommits });
        this.setState({ reportItems: reportArray.length > 0 ? reportArray.sort((a, b) => { return a.user.localeCompare(b.user) }).concat([totalReport]) : reportArray });
        this.setState({ reportOrganizedData: reportOrganizedData });
        this.setState({ chain: this.getBreadcrumbChainList(this.state.user, this.state.selectedUser, chain) });
        this.setState({ userIssueList: userIssueList })
        this.setState({ selectedUserNicename: result.nicename })
        this.setState({ riskData: { riskList: riskList, risksAvailable: chain ? chain.length > 0 : false } });
        this.setState({ riskCounts: riskCounts });
        this.setState({ riskGraphType: riskGraphType });
        this.setState({ visibleRiskGraphData: selectedGraphData });
        this.setState({ riskGraphData: selectedGraphData });
        this.setState({ isUserOnLeave: userOnLeave });
        this.setState({ reportsOnLeaveCount: reportsOnLeave });
      } else {
        if (result) {
          this.setState({ personalInstallStatus: installationStatus(0, 0, 0, false) });
        }
        else {
          this.setState({ personalInstallStatus: <div><strong>Could not load your status.</strong><br /> Please try refreshing the page.</div> });
        }
        this.setState({ personalItems: [] });
        this.setState({ personalCommitItems: [] });
        this.setState({ reportItems: [] });
        this.setState({ reportOrganizedData: [] });
        this.setState({ chain: this.getBreadcrumbChainList(this.state.user, this.state.selectedUser, []) });
        this.setState({ userIssueList: [] })
        this.setState({ selectedUserNicename: this.state.selectedUser })
        this.setState({ riskData: { riskList: [], risksAvailable: false } });
        this.setState({ visibleRiskGraphData: [] });
        this.setState({ riskGraphData: [] });
      }
      this.setState({ dataLoad: false });
      if (!this.state.dataLoad && !this.state.delagateLoad) {
        this.setState({ loading: false });
      }
    }
  }

  async loadDelegations(user) {
    let result;
    this.setState({ delagateLoad: true });
    try {
      result = await getDelegationsForUser(this.client, {}, { username: user })
    } finally {
      if (result) {
        this.setState({ delegations: result });
      } else {
        this.setState({ delegations: [] });
      }
      this.setState({ delagateLoad: false });
      if (!this.state.dataLoad && !this.state.delagateLoad) {
        this.setState({ loading: false });
      }
    }
  }

  setModalToBlockedCommit(data, user) {
    this.setState({ modalObject: blockedCommitModal(this.setModalVisibility, data, user) });
  }

  setModalToRiskData(riskInfo) {
    this.setRiskPageIndex(1, riskInfo)
  }

  setModalVisibility(visibility) {
    this.setState({ modalVisible: visibility })
  }

  setCurrentPageIndex(index) {
    this.setState({ currentPageIndex: index })
  }

  setRiskPageIndex(index, riskInfo) {
    this.setState({ riskPageIndex: index }, () => {
      this.setState({ modalObject: riskModal(this.setModalVisibility, riskInfo, this.state.riskPageIndex, this.setRiskPageIndex) });
    })
  }

  hasReportsOrDelegations() {
    return ((this.state.reportItems && this.state.reportItems.length > 0) || (this.state.delegations && this.state.delegations.length > 0))
  }

  getTabs() {
    if (this.hasReportsOrDelegations()) {
      return [this.getPersonalTab(), this.getReportsTab(), this.getRiskTab()]
    }
    else {
      return [this.getPersonalTab(), this.getRiskTab()]
    }
  }

  getPersonalTab() {
    return {
      label: "Personal",
      id: "personal",
      content:
        <ColumnLayout>
          <ColumnLayout columns={2}>
            {this.state.selectedUser !== "ROOT" &&
              <Container header={
                <Header
                  variant="h2"
                  description="Updates once a day"
                >
                  Personal Status for {this.state.selectedUser}
                </Header>
              }>
                <ColumnLayout borders="vertical" columns={1}>
                  <ValueWithLabel label="Risk Status">
                    {this.state.loading ? <Spinner size="large" /> : totalRisks(this.state.riskData)}
                  </ValueWithLabel>
                </ColumnLayout>
              </Container>
            }
          </ColumnLayout>

          {this.state.selectedUser !== "ROOT" &&
            <Container disableContentPaddings={true}>
              <Table
                columnDefinitions={personalColumnDefinitions}
                items={this.state.personalItems}
                loadingText="Loading data"
                loading={this.state.loading}
                empty={<div>No data</div>}
                header={
                  <Header
                    info={<Link external variant="info"
                      href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#20">Info</Link>}>
                    Personal Stats for {this.state.selectedUser}
                  </Header>}
              />
            </Container>
          }

          {this.state.selectedUser !== "ROOT" &&
            <Container disableContentPaddings={true}>
              <Table
                columnDefinitions={personalColumnDefinitions}
                items={this.state.personalCommitItems}
                loadingText="Loading data"
                loading={this.state.loading}
                empty={<div>No data</div>}
                header={
                  <div>
                    <Header
                      info={<Link external variant="info"
                        href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#20">Info</Link>}
                    >
                      Personal Blocked Commit Breakdown for {this.state.selectedUser}
                    </Header>
                  </div>}
              />
            </Container>
          }
        </ColumnLayout>
    }
  }

  getReportsTab() {
    return {
      label: "Reports",
      id: "reports",
      content:
        <ColumnLayout>
          <ColumnLayout columns={3}>
            {this.state.delegations && this.state.delegations.length > 0 &&
              <Container disableContentPaddings={true} header={<Header>Your Assigned Segment Leaders</Header>}>
                <div style={{ height: "154px", overflow: "auto" }}>
                  <Table
                    columnDefinitions={[
                      {
                        id: "user",
                        header: "User",
                        cell: item => <b>{item}</b>
                      },
                      {
                        id: "installed",
                        cell: item =>
                          <div style={{ display: "flex", justifyContent: 'center' }}>
                            <Button
                              id={`${item}DelegationButton`}
                              variant="primary"
                              wrapText={false}
                              iconAlign="right"
                              iconName="angle-right"
                              onClick={async () => {
                                this.setState({ selectedUser: item });
                                this.setState({ chain: this.state.chain.concat([{ text: item, href: '#' }]) });
                                this.setState({ loading: true });
                                await this.loadData(item);
                                this.setState({ loading: false });
                              }}>
                              View Reports
                            </Button>
                          </div>
                      }
                    ]}
                    items={this.state.delegations}
                    loadingText="Loading data"
                    loading={this.state.loading}
                    empty={<div>No data</div>}
                  />
                </div>
              </Container>}
          </ColumnLayout>

          {
            (this.state.reportItems && this.state.reportItems.length > 0) &&
            <Container disableContentPaddings={true}>
              <Table
                ariaLabels={{
                  selectionGroupLabel: "Items selection",
                  allItemsSelectionLabel: () =>
                    `${this.state.selectedItems.length} ${this.state.selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                  itemSelectionLabel: (item) => {
                    var isItemSelected = this.state.selectedItems.filter(
                      i => i.name === item.name
                    ).length;
                    return `${item.name} is ${isItemSelected ? "" : "not"
                      } selected`;
                  }
                }}
                columnDefinitions={[
                  {
                    id: "user",
                    header: "User",
                    cell: item =>
                      <Button
                        id={`${item.username}Button`}
                        variant="link"
                        wrapText={false}
                        iconAlign="right"
                        disabled={item.username === "total"}
                        iconName="angle-right"
                        onClick={async () => {
                          this.setState({ selectedUser: item.username });
                          this.setState({ chain: this.state.chain.concat([{ text: item.username, href: '#' }]) });
                          this.setState({ loading: true });
                          await this.loadData(item.username);
                          this.setState({ loading: false });
                        }}>
                        {item.isUserOnLeave ? (LocalStorage.get("theme") === "dark") ?
                          <LeaveIcon style={{ height: '16px', width: '16px', fill: 'white' }} /> :
                          <LeaveIcon style={{ height: '16px', width: '16px', fill: 'black' }} /> : ""}
                        {item.user || "0"}
                      </Button>
                  },
                  {
                    id: "bypassAttempts",
                    header: "Bypass Attempts",
                    cell: item => item.username !== "total" && (item.bypassAttempts || item.bypassAttempts > 0 ? negativeIconBuilder(item.bypassAttempts) : positiveIconBuilder(0))
                  },
                  {
                    id: "blockedCommits",
                    header: "Blocked Commits (30 Days)",
                    cell: item => item.username !== "total" && (item.reportPersonalNumBlockedCommits || item.reportPersonalNumBlockedCommits > 0 ? negativeIconBuilder(item.reportPersonalNumBlockedCommits) : positiveIconBuilder(0))
                  },
                  {
                    id: "reports",
                    header: "Number of Reports",
                    cell: item => item.reports || "0"
                  },
                  {
                    id: "reportsOnLeave",
                    header: "Reports on leave",
                    cell: item => item.reportsOnLeave > 0 ? negativeIconBuilder(item.reportsOnLeave) : positiveIconBuilder(0)
                  },
                  {
                    id: "reportsBypasses",
                    header: "Report's Bypass Attempts",
                    cell: item => item.reportBypassAttempts && item.reportBypassAttempts > 0 ? negativeIconBuilder(item.reportBypassAttempts) : positiveIconBuilder(0)
                  },
                  {
                    id: "reportsBlockedCommits",
                    header: "Report's Blocked Commits (30 Days)",
                    cell: item =>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: "1" }}>
                          {item.reportNumBlockedCommits > 0 ? negativeIconBuilder(item.reportNumBlockedCommits) : positiveIconBuilder(item.reportNumBlockedCommits)}
                        </div>
                        <div style={{ flex: "2" }}>
                          <Button id={`${item.username}ReportsBlockedCommitsButton`} variant="primary" onClick={() => {
                            this.setModalToBlockedCommit(item.reportBlockedCommits, item.user)
                            this.setState({ modalVisible: true })
                          }}>
                            View Commits
                          </Button>
                        </div>
                      </div>
                  }
                ]}
                items={this.state.reportItems}
                loadingText="Loading data"
                loading={this.state.loading}
                empty={<div>No data</div>}
                header={
                  <Header description={
                    <div style={{ alignItems: "center", display: "flex" }}>
                      {(LocalStorage.get("theme") === "dark") ?
                        <LeaveIcon style={{ height: '14px', width: '14px', fill: 'white' }} /> :
                        <LeaveIcon style={{ height: '14px', width: '14px', fill: 'black' }} />}
                      {this.state.reportsOnLeaveCount} direct reports on leave
                    </div>}
                  >
                    Direct Reports for {this.state.selectedUser} ({this.state.reportItems ? this.state.reportItems.length - 1 : 0})
                  </Header>
                }
              />
            </Container>
          }

          {(this.state.reportItems && this.state.reportItems.length > 0) &&
            <Container disableContentPaddings={true} header={
              <Header info={<Link external variant="info"
                href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#20">Info</Link>}>
                All Reports With Commit Blocks, Multiple Bypasses and Failed Check-ins
              </Header>}>
              <Tabs
                tabs={[
                  {
                    label: `Secret Key (${this.state.userIssueList.length > 0 ? this.state.userIssueList[0].userList.length : 0})`,
                    id: "secret",
                    content:
                      this.state.userIssueList.length > 0 &&
                      <Table
                        header={<Header>{this.state.userIssueList[0].metricTitle}</Header>}
                        columnDefinitions={[
                          {
                            id: "reports",
                            header: "Reports with Risks (30 days)",
                            cell: item =>
                              <Button
                                id={`${item}SecretButton`}
                                variant="link"
                                wrapText={false}
                                iconAlign="right"
                                iconName="angle-right"
                                onClick={async () => {
                                  this.setState({ selectedUser: item });
                                  this.setState({ chain: this.state.chain.concat([{ text: item, href: '#' }]) });
                                  this.setState({ loading: true });
                                  await this.loadData(item);
                                  this.setState({ loading: false });
                                }}>
                                {item || "0"}
                              </Button>
                          },
                          {
                            id: "phonetool",
                            header: "Contact",
                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                          }
                        ]}
                        items={
                          this.state.userIssueList[0].userList.slice((this.state.currentPageIndex - 1) * this.state.userIssueListTableLen, this.state.currentPageIndex * this.state.userIssueListTableLen)
                        }
                        loadingText="Loading data"
                        loading={this.state.loading}
                        empty={<div>No data</div>}
                        disableContentPaddings={true}
                        pagination={
                          <Pagination
                            pagesCount={Math.ceil(this.state.userIssueList[0].userList.length / this.state.userIssueListTableLen)}
                            ariaLabels={{
                              nextPageLabel: "Next page",
                              previousPageLabel: "Previous page",
                              pageLabel: pageNumber =>
                                `Page ${pageNumber} of all pages`
                            }}
                            currentPageIndex={this.state.currentPageIndex}
                            onChange={({ detail }) =>
                              this.setCurrentPageIndex(detail.currentPageIndex)
                            }
                          />
                        }
                      />
                  },
                  {
                    label: `Access Key (${this.state.userIssueList.length > 1 ? this.state.userIssueList[1].userList.length : 0})`,
                    id: "access",
                    content:
                      this.state.userIssueList.length > 1 &&
                      <Table
                        header={<Header>{this.state.userIssueList[1].metricTitle}</Header>}
                        columnDefinitions={[
                          {
                            id: "reports",
                            header: "Reports with Risks (30 days)",
                            cell: item =>
                              <Button
                                id={`${item}AccessButton`}
                                variant="link"
                                wrapText={false}
                                iconAlign="right"
                                iconName="angle-right"
                                onClick={async () => {
                                  this.setState({ selectedUser: item });
                                  this.setState({ chain: this.state.chain.concat([{ text: item, href: '#' }]) });
                                  this.setState({ loading: true });
                                  await this.loadData(item);
                                  this.setState({ loading: false });
                                }}>
                                {item || "0"}
                              </Button>
                          },
                          {
                            id: "phonetool",
                            header: "Contact",
                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                          }
                        ]}
                        items={
                          this.state.userIssueList[1].userList.slice((this.state.currentPageIndex - 1) * this.state.userIssueListTableLen, this.state.currentPageIndex * this.state.userIssueListTableLen)
                        }
                        loadingText="Loading data"
                        loading={this.state.loading}
                        empty={<div>No data</div>}
                        disableContentPaddings={true}
                        pagination={
                          <Pagination
                            pagesCount={Math.ceil(this.state.userIssueList[1].userList.length / this.state.userIssueListTableLen)}
                            ariaLabels={{
                              nextPageLabel: "Next page",
                              previousPageLabel: "Previous page",
                              pageLabel: pageNumber =>
                                `Page ${pageNumber} of all pages`
                            }}
                            currentPageIndex={this.state.currentPageIndex}
                            onChange={({ detail }) =>
                              this.setCurrentPageIndex(detail.currentPageIndex)
                            }
                          />
                        }
                      />
                  },
                  {
                    label: `Private RSA Key (${this.state.userIssueList.length > 2 ? this.state.userIssueList[2].userList.length : 0})`,
                    id: "privateRSA",
                    content:
                      this.state.userIssueList.length > 2 &&
                      <Table
                        header={<Header>{this.state.userIssueList[2].metricTitle}</Header>}
                        columnDefinitions={[
                          {
                            id: "reports",
                            header: "Reports with Risks (30 days)",
                            cell: item =>
                              <Button
                                id={`${item}PrivateRSAButton`}
                                variant="link"
                                wrapText={false}
                                iconAlign="right"
                                iconName="angle-right"
                                onClick={async () => {
                                  this.setState({ selectedUser: item });
                                  this.setState({ chain: this.state.chain.concat([{ text: item, href: '#' }]) });
                                  this.setState({ loading: true });
                                  await this.loadData(item);
                                  this.setState({ loading: false });
                                }}>
                                {item || "0"}
                              </Button>
                          },
                          {
                            id: "phonetool",
                            header: "Contact",
                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                          }
                        ]}
                        items={
                          this.state.userIssueList[2].userList.slice((this.state.currentPageIndex - 1) * this.state.userIssueListTableLen, this.state.currentPageIndex * this.state.userIssueListTableLen)
                        }
                        loadingText="Loading data"
                        loading={this.state.loading}
                        empty={<div>No data</div>}
                        disableContentPaddings={true}
                        pagination={
                          <Pagination
                            pagesCount={Math.ceil(this.state.userIssueList[2].userList.length / this.state.userIssueListTableLen)}
                            ariaLabels={{
                              nextPageLabel: "Next page",
                              previousPageLabel: "Previous page",
                              pageLabel: pageNumber =>
                                `Page ${pageNumber} of all pages`
                            }}
                            currentPageIndex={this.state.currentPageIndex}
                            onChange={({ detail }) =>
                              this.setCurrentPageIndex(detail.currentPageIndex)
                            }
                          />
                        }
                      />
                  },
                  {
                    label: `Multiple Bypass (${this.state.userIssueList.length > 3 ? this.state.userIssueList[3].userList.length : 0})`,
                    id: "bypass",
                    content:
                      this.state.userIssueList.length > 3 &&
                      <Table
                        header={<Header>{this.state.userIssueList[3].metricTitle}</Header>}
                        columnDefinitions={[
                          {
                            id: "reports",
                            header: "Reports with Risks (30 days)",
                            cell: item =>
                              <Button
                                id={`${item}BypassButton`}
                                variant="link"
                                wrapText={false}
                                iconAlign="right"
                                iconName="angle-right"
                                onClick={async () => {
                                  this.setState({ selectedUser: item });
                                  this.setState({ chain: this.state.chain.concat([{ text: item, href: '#' }]) });
                                  this.setState({ loading: true });
                                  await this.loadData(item);
                                  this.setState({ loading: false });
                                }}>
                                {item || "0"}
                              </Button>
                          },
                          {
                            id: "phonetool",
                            header: "Contact",
                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                          }
                        ]}
                        items={
                          this.state.userIssueList[3].userList.slice((this.state.currentPageIndex - 1) * this.state.userIssueListTableLen, this.state.currentPageIndex * this.state.userIssueListTableLen)
                        }
                        loadingText="Loading data"
                        loading={this.state.loading}
                        empty={<div>No data</div>}
                        disableContentPaddings={true}
                        pagination={
                          <Pagination
                            pagesCount={Math.ceil(this.state.userIssueList[3].userList.length / this.state.userIssueListTableLen)}
                            ariaLabels={{
                              nextPageLabel: "Next page",
                              previousPageLabel: "Previous page",
                              pageLabel: pageNumber =>
                                `Page ${pageNumber} of all pages`
                            }}
                            currentPageIndex={this.state.currentPageIndex}
                            onChange={({ detail }) =>
                              this.setCurrentPageIndex(detail.currentPageIndex)
                            }
                          />
                        }
                      />
                  },
                  {
                    label: `Failed Check-ins (${this.state.userIssueList.length > 4 ? this.state.userIssueList[4].userList.length : 0})`,
                    id: "checkins",
                    content:
                      this.state.userIssueList.length > 4 &&
                      <Table
                        header={<Header>{this.state.userIssueList[4].metricTitle}</Header>}
                        columnDefinitions={[
                          {
                            id: "reports",
                            header: "Reports with Risks (30 days)",
                            cell: item =>
                              <Button
                                id={`${item}CheckinsButton`}
                                variant="link"
                                wrapText={false}
                                iconAlign="right"
                                iconName="angle-right"
                                onClick={async () => {
                                  this.setState({ selectedUser: item });
                                  this.setState({ chain: this.state.chain.concat([{ text: item, href: '#' }]) });
                                  this.setState({ loading: true });
                                  await this.loadData(item);
                                  this.setState({ loading: false });
                                }}>
                                {item || "0"}
                              </Button>
                          },
                          {
                            id: "phonetool",
                            header: "Contact",
                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                          }
                        ]}
                        items={
                          this.state.userIssueList[4].userList.slice((this.state.currentPageIndex - 1) * this.state.userIssueListTableLen, this.state.currentPageIndex * this.state.userIssueListTableLen)
                        }
                        loadingText="Loading data"
                        loading={this.state.loading}
                        empty={<div>No data</div>}
                        disableContentPaddings={true}
                        pagination={
                          <Pagination
                            pagesCount={Math.ceil(this.state.userIssueList[4].userList.length / this.state.userIssueListTableLen)}
                            ariaLabels={{
                              nextPageLabel: "Next page",
                              previousPageLabel: "Previous page",
                              pageLabel: pageNumber =>
                                `Page ${pageNumber} of all pages`
                            }}
                            currentPageIndex={this.state.currentPageIndex}
                            onChange={({ detail }) =>
                              this.setCurrentPageIndex(detail.currentPageIndex)
                            }
                          />
                        }
                      />
                  },
                ]}
                onChange={({ detail }) =>
                  this.setCurrentPageIndex(1)}
              />
            </Container>
          }
        </ColumnLayout>,
    }
  }

  getRiskTab() {
    return {
      label: (
        <div>
          Risk Monitoring{" "}
          {this.state.riskData.riskList.length > 0
            ? <Badge color="red">{this.state.riskData.riskList.length}</Badge>
            : <Badge color="grey">0</Badge>}

        </div>
      ),
      id: "risk",
      content:
        <ColumnLayout>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  (this.state.reportItems && this.state.reportItems.length > 0) &&
                  <SegmentedControl
                    selectedId={this.state.riskGraphType}
                    onChange={({ detail }) => {
                      const riskGraphData = formatRiskGraphData(this.state.riskCounts)
                      const selectedGraphData = detail.selectedId === "overall" ? riskGraphData.overallData : riskGraphData.personalData
                      this.setState({ visibleRiskGraphData: selectedGraphData });
                      this.setState({ riskGraphData: selectedGraphData });
                      this.setState({ riskGraphType: detail.selectedId })
                    }
                    }
                    label="Risk graph segmented control"
                    options={[
                      { text: "Overall", id: "overall" },
                      { text: "Personal", id: "personal" },
                    ]}
                  />
                }
              >
                Risk Trends
              </Header>
            }
          >
            <LineChart
              series={this.state.riskGraphData}
              visibleSeries={this.state.visibleRiskGraphData}
              i18nStrings={{
                filterLabel: "Filter by risk",
                filterPlaceholder: "Filter risk type",
                filterSelectedAriaLabel: "selected",
                legendAriaLabel: "Legend",
                chartAriaRoleDescription: "line chart",
                xTickFormatter: e =>
                  e
                    .toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })
                    .split(",")
                    .join("\n"),
                yTickFormatter: undefined
              }}
              ariaLabel="Single data series line chart"
              detailPopoverSize="large"
              errorText="Error loading data"
              height={300}
              loadingText="Loading chart"
              recoveryText="Retry"
              statusType={this.state.loading ? "loading" : "finished"}
              xScaleType="time"
              xTitle="Date"
              yScaleType="linear"
              yTitle="Number of Risks"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No data available</b>
                  <Box variant="p" color="inherit">
                    There is no data available
                  </Box>
                </Box>
              }
              noMatch={
                <Box textAlign="center" color="inherit">
                  <b>No matching data</b>
                  <Box variant="p" color="inherit">
                    There is no matching data to display
                  </Box>
                  <Button onClick={
                    async () => {
                      this.setState({ visibleRiskGraphData: this.state.riskGraphData })
                    }
                  }>
                    Clear filter
                  </Button>
                </Box>
              }
              onFilterChange={({ detail }) => {
                this.setState({ visibleRiskGraphData: detail.visibleSeries });
              }
              }
            />
          </Container>
          <Container disableContentPaddings={true}>
            <Table
              columnDefinitions={riskColumnDefinitions(this.setModalToRiskData, this.setModalVisibility)}
              items={this.state.riskData.riskList}
              resizableColumns
              loadingText="Loading data"
              loading={this.state.loading}
              empty={<div>No data</div>}
              header={
                (this.state.reportItems && this.state.reportItems.length > 0) ?
                  <Header
                    info={<Link external variant="info"
                      href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#34">Info</Link>}>
                    All Active Risks
                  </Header> :
                  <Header
                    info={<Link external variant="info"
                      href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#34">Info</Link>}>
                    Your Active Risks
                  </Header>
              }

            />
          </Container>
        </ColumnLayout>
    }
  }

  render() {
    return (
      <ColumnLayout>
        <div className=" offset-s-0">
          <BreadcrumbGroup
            id="breadcrumb-group"
            items={[{ text: "Code Defender", href: "/" }].concat(this.state.chain)}
            ariaLabel="Breadcrumbs"
            onClick={async (clickDetail) => {
              if (clickDetail.detail.text !== 'Code Defender') {
                this.setState({ selectedUser: clickDetail.detail.text });
                this.setState({ chain: this.state.chain.slice(0, this.state.chain.indexOf(clickDetail.detail.item) + 1) });
                this.setState({ loading: true });
                await this.loadData(clickDetail.detail.text);
                this.setState({ loading: false });

              }
            }}
          />
        </div>

        {this.hasReportsOrDelegations() &&
          <form style={{ width: "70%" }}>
            <p>Search my reports</p>

            <FormField stretch={true}>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                  <Input
                    id="searchInput"
                    type="search"
                    placeholder="Enter report alias"
                    disableBrowserAutocorrect={true}
                    autocomplete={false}
                    ariaRequired={true}
                    onChange={(({ detail }) => this.setState({ searchValue: detail.value }))}
                    value={this.state.searchValue}
                  />
                </div>
                <div style={{ minWidth: '108px' }}>
                  <Button id="searchButton" variant="primary" loading={this.state.loading} iconName="search" onClick={this.runSearch.bind(this)}>Search</Button>
                </div>
              </div>

            </FormField>
          </form>
        }

        <Header variant="h1">{this.state.selectedUserNicename}</Header>

        {this.state.modalVisible && this.state.modalObject}

        <Tabs
          tabs={this.getTabs()}
        />
      </ColumnLayout >
    )
  }
}

export { MetricsContent }
export default MetricsHomePage;